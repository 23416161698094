import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Colors = () => import('@/views/theme/Colors')
const Typography = () => import('@/views/theme/Typography')

// const Charts = () => import('@/views/charts/Charts')
// const Widgets = () => import('@/views/widgets/Widgets')

// Views - Components
const Cards = () => import('@/views/base/Cards')
const Switches = () => import('@/views/base/Switches')
const Tabs = () => import('@/views/base/Tabs')
const Breadcrumbs = () => import('@/views/base/Breadcrumbs')
const Carousels = () => import('@/views/base/Carousels')
const Collapses = () => import('@/views/base/Collapses')
const Jumbotrons = () => import('@/views/base/Jumbotrons')
const ListGroups = () => import('@/views/base/ListGroups')
const Navs = () => import('@/views/base/Navs')
const Navbars = () => import('@/views/base/Navbars')
const Paginations = () => import('@/views/base/Paginations')
const Popovers = () => import('@/views/base/Popovers')
const ProgressBars = () => import('@/views/base/ProgressBars')
const Tables = () => import('@/views/tables/Tables')
const AdvancedTables = () => import('@/views/tables/AdvancedTables')
const Tooltips = () => import('@/views/base/Tooltips')

// Views - Buttons
const StandardButtons = () => import('@/views/buttons/StandardButtons')
const ButtonGroups = () => import('@/views/buttons/ButtonGroups')
const Dropdowns = () => import('@/views/buttons/Dropdowns')
const BrandButtons = () => import('@/views/buttons/BrandButtons')
const LoadingButtons = () => import('@/views/buttons/LoadingButtons')

// Views - Editors
const TextEditors = () => import('@/views/editors/TextEditors')
const CodeEditors = () => import('@/views/editors/CodeEditors')

// Views - Forms
const BasicForms = () => import('@/views/forms/Forms')
const AdvancedForms = () => import('@/views/forms/AdvancedForms')
const ValidationForms = () => import('@/views/forms/ValidationForms')
const MultiSelect = () => import('@/views/forms/MultiSelect')

// Views GoogleMaps
const GoogleMaps = () => import('@/views/GoogleMaps')

// Views - Icons
const CoreUIIcons = () => import('@/views/icons/CoreUIIcons')
const Brands = () => import('@/views/icons/Brands')
const Flags = () => import('@/views/icons/Flags')

// Views - Notifications
const Alerts = () => import('@/views/notifications/Alerts')
const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import('@/views/notifications/Modals')
const Toaster = () => import('@/views/notifications/Toaster')


// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')
const VerifyAccount = () => import('@/views/pages/VerifyAccount')
const Favourites = () => import('@/views/pages/Favourites')
const Profile = () => import('@/views/pages/Profile')
const Notification = () => import('@/views/pages/Notification')
const PricingPlan = () => import('@/views/pages/PricingPlan')
const AICredit = () => import('@/views/pages/AICredit')
const ResetPassword = () => import('@/views/pages/ResetPassword')
const PurchasePlan = () => import('@/views/pages/PurchasePlan')
const Folder = () => import('@/views/pages/Folder')
// Users
// const Users = () => import('@/views/users/Users')
// const User = () => import('@/views/users/User')

// Plugins
const Draggable = () => import('@/views/plugins/Draggable')
const Calendar = () => import('@/views/plugins/Calendar')
const Spinners = () => import('@/views/plugins/Spinners')

// Apps -> Invoice
const Invoice = () => import('@/views/apps/invoicing/Invoice')

// Apps -> Email
const EmailApp = () => import('@/views/apps/email/EmailApp')
const Compose = () => import('@/views/apps/email/Compose')
const Inbox = () => import('@/views/apps/email/Inbox')
const Message = () => import('@/views/apps/email/Message')


const FloorPlanList = () => import('@/views/tenants/FloorPlanList')
const FloorPlan = () => import('@/views/tenants/FloorPlan')
const FloorPlanTravelDistance = () => import('@/views/tenants/FloorPlanTravelDistance')
const FloorPlanRoom = () => import('@/views/tenants/FloorPlanRoom')
const FloorPlanCompliance = () => import('@/views/tenants/FloorPlanCompliance')

const BuildingCodeList = () => import('@/views/tenants/BuildingCodeList')
const BuildingCode = () => import('@/views/tenants/BuildingCode')

const BuildingCodeCategoryList = () => import('@/views/tenants/BuildingCodeCategoryList')
const BuildingCodeCategory = () => import('@/views/tenants/BuildingCodeCategory')

const BuildingRuleList = () => import('@/views/tenants/BuildingRuleList')
const BuildingRule = () => import('@/views/tenants/BuildingRule')

const OccupantLoadFactorList = () => import('@/views/tenants/OccupantLoadFactorList')
const OccupantLoadFactor = () => import('@/views/tenants/OccupantLoadFactor')

const MinimumNumberOfExitList = () => import('@/views/tenants/MinimumNumberOfExitList')
const MinimumNumberOfExit = () => import('@/views/tenants/MinimumNumberOfExit')
const EgressParameterList = () => import('@/views/tenants/EgressParameterList')
const EgressParameter = () => import('@/views/tenants/EgressParameter')


const Room = () => import('@/views/tenants/Room')

const Drawing = () => import('@/views/tenants/Drawing')
const LoadImage = () => import('@/views/tenants/LoadImage')
const WorkArea = () => import('@/views/pages/WorkArea')
const Workspace = () => import('@/views/Workspace')
// const WorkSpace = () => import('@/views/drawings/WorkSpace')

const WorkSpace2 = () => import('@/views/tenants/WorkSpace')

const UserList = () => import('@/views/admins/UserList')
const User = () => import('@/views/admins/User')


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})


router.beforeEach((to, from, next) => {
  var isAuth = auth.check();
  if (!isAuth) {
    if (to.name == 'Login') {
      next();
    } else if (to.name == 'Register') {
      next();
    } else if (to.name === 'VerifyAccount') {
      next();
    }
    else {
      next({ name: 'Login' });
    }
  }
  else {
    next();
  }
})

export default router

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/folder',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard/:id',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: 'folder',
          name: 'Folder',
          component: Folder
        },
        {
          path: 'favourites',
          name: 'Favourites',
          component: Favourites
        },
        {
          path: 'profile',
          name: 'Profile',
          component: Profile
        },
        {
          path: 'notification',
          name: 'Notification',
          component: Notification
        },
        {
          path: 'pricing-plan',
          name: 'Pricing Plan',
          component: PricingPlan
        },
        {
          path: 'ai-credit',
          name: 'AI Credit',
          component: AICredit
        },
        {
          path: 'reset-password',
          name: 'Reset Password',
          component: ResetPassword
        },
        {
          path: 'purchase-plan',
          name: 'Purchase Plan',
          component: PurchasePlan
        },
        {
          path: 'theme',
          redirect: '/theme/colors',
          name: 'Theme',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'colors',
              name: 'Colors',
              component: Colors
            },
            {
              path: 'typography',
              name: 'Typography',
              component: Typography
            }
          ]
        },
        {
          path: 'tables',
          redirect: '/tables/tables',
          name: 'Tables',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'tables',
              name: 'Basic tables',
              component: Tables
            },
            {
              path: 'advanced-tables',
              name: 'Advanced tables',
              component: AdvancedTables
            }
          ]
        },
        {
          path: 'tenant',
          redirect: '/tenant/floorPlanList',
          name: 'FloorPlan',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'floorPlan/:id',
              name: 'floorPlanById',
              component: FloorPlan
            },
            {
              path: 'floorPlan',
              name: 'floorPlan',
              component: FloorPlan
            },
            {
              path: 'floorPlanList',
              name: 'floorPlanList',
              component: FloorPlanList
            },
            {
              path: 'floorPlanTravelDistance',
              name: 'floorPlanTravelDistance',
              component: FloorPlanTravelDistance
            },
            {
              path: 'floorPlanCompliance',
              name: 'floorPlanCompliance',
              component: FloorPlanCompliance
            },
            {
              path: 'floorPlanRoom',
              name: 'floorPlanRoom',
              component: FloorPlanRoom
            },
          ]
        },
        {
          path: 'tenant',
          redirect: '/tenant/buildingCodeList',
          name: 'BuildingCode',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'buildingCodeCategory/:id',
              name: 'buildingCodeCategoryById',
              component: BuildingCodeCategory
            },
            {
              path: 'buildingCodeCategory',
              name: 'buildingCodeCategory',
              component: BuildingCodeCategory
            },
            {
              path: 'buildingCodeCategoryList',
              name: 'buildingCodeCategoryList',
              component: BuildingCodeCategoryList
            },
            {
              path: 'buildingCode/:id',
              name: 'buildingCodeById',
              component: BuildingCode
            },
            {
              path: 'buildingCode',
              name: 'buildingCode',
              component: BuildingCode
            },
            {
              path: 'buildingCodeList',
              name: 'buildingCodeList',
              component: BuildingCodeList
            },
            {
              path: 'buildingRule/:id',
              name: 'buildingRuleById',
              component: BuildingRule
            },
            {
              path: 'buildingRule',
              name: 'buildingRule',
              component: BuildingRule
            },
            {
              path: 'buildingRuleList',
              name: 'buildingRuleList',
              component: BuildingRuleList
            },
            {
              path: 'occupantLoadFactor/:id',
              name: 'occupantLoadFactorById',
              component: OccupantLoadFactor
            },
            {
              path: 'occupantLoadFactor',
              name: 'occupantLoadFactor',
              component: OccupantLoadFactor
            },
            {
              path: 'occupantLoadFactorList',
              name: 'occupantLoadFactorList',
              component: OccupantLoadFactorList
            },
            {
              path: 'minimumNumberOfExit/:id',
              name: 'minimumNumberOfExitById',
              component: MinimumNumberOfExit
            },
            {
              path: 'minimumNumberOfExit',
              name: 'minimumNumberOfExit',
              component: MinimumNumberOfExit
            },
            {
              path: 'minimumNumberOfExitList',
              name: 'minimumNumberOfExitList',
              component: MinimumNumberOfExitList
            },
            {
              path: 'egressParameter/:id',
              name: 'egressParameterById',
              component: EgressParameter
            },
            {
              path: 'egressParameter',
              name: 'egressParameter',
              component: EgressParameter
            },
            {
              path: 'egressParameterList',
              name: 'egressParameterList',
              component: EgressParameterList
            },
            {
              path: 'room/:id',
              name: 'roomById',
              component: Room
            },
            {
              path: 'room',
              name: 'room',
              component: Room
            },
            {
              path: 'drawing',
              name: 'drawing',
              component: Drawing
            },
            {
              path: 'loadImage',
              name: 'loadImage',
              component: LoadImage
            },
          ]
        },

        {
          path: 'admins',
          redirect: '/admins/userList',
          name: 'UserList',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'user/:id',
              name: 'UserById',
              component: User
            },
            {
              path: 'user',
              name: 'User',
              component: User
            },
            {
              path: 'userList',
              name: 'UserList',
              component: UserList
            },

          ]
        },

        {
          path: 'base',
          redirect: '/base/cards',
          name: 'Base',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'breadcrumbs',
              name: 'Breadcrumbs',
              component: Breadcrumbs
            },
            {
              path: 'cards',
              name: 'Cards',
              component: Cards
            },
            {
              path: 'carousels',
              name: 'Carousels',
              component: Carousels
            },
            {
              path: 'collapses',
              name: 'Collapses',
              component: Collapses
            },
            {
              path: 'jumbotrons',
              name: 'Jumbotrons',
              component: Jumbotrons
            },
            {
              path: 'list-groups',
              name: 'List Groups',
              component: ListGroups
            },
            {
              path: 'navs',
              name: 'Navs',
              component: Navs
            },
            {
              path: 'navbars',
              name: 'Navbars',
              component: Navbars
            },
            {
              path: 'paginations',
              name: 'Paginations',
              component: Paginations
            },
            {
              path: 'popovers',
              name: 'Popovers',
              component: Popovers
            },
            {
              path: 'progress-bars',
              name: 'Progress Bars',
              component: ProgressBars
            },
            {
              path: 'switches',
              name: 'Switches',
              component: Switches
            },
            {
              path: 'tabs',
              name: 'Tabs',
              component: Tabs
            },
            {
              path: 'tooltips',
              name: 'Tooltips',
              component: Tooltips
            }
          ]
        },
        {
          path: 'buttons',
          redirect: '/buttons/standard-buttons',
          name: 'Buttons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'standard-buttons',
              name: 'Standard Buttons',
              component: StandardButtons
            },
            {
              path: 'button-groups',
              name: 'Button Groups',
              component: ButtonGroups
            },
            {
              path: 'dropdowns',
              name: 'Dropdowns',
              component: Dropdowns
            },
            {
              path: 'brand-buttons',
              name: 'Brand Buttons',
              component: BrandButtons
            },
            {
              path: 'loading-buttons',
              name: 'Loading Buttons',
              component: LoadingButtons
            }
          ]
        },
        {
          path: 'editors',
          redirect: '/editors/text-editors',
          name: 'Editors',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'text-editors',
              name: 'Text Editors',
              component: TextEditors
            },
            {
              path: 'code-editors',
              name: 'Code Editors',
              component: CodeEditors
            }
          ]
        },
        {
          path: 'forms',
          redirect: '/forms/basic-forms',
          name: 'Forms',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'basic-forms',
              name: 'Basic Forms',
              component: BasicForms
            },
            {
              path: 'advanced-forms',
              name: 'Advanced Forms',
              component: AdvancedForms
            },
            {
              path: 'validation-forms',
              name: 'Form Validation',
              component: ValidationForms
            },
            {
              path: 'multi-select',
              name: 'Multiselect',
              component: MultiSelect
            },
          ]
        },
        {
          path: 'google-maps',
          name: 'Google Maps',
          component: GoogleMaps
        },
        {
          path: 'icons',
          redirect: '/icons/font-awesome',
          name: 'Icons',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'coreui-icons',
              name: 'CoreUI Icons',
              component: CoreUIIcons
            },
            {
              path: 'flags',
              name: 'Flags',
              component: Flags
            },
            {
              path: 'brands',
              name: 'Brands',
              component: Brands
            }
          ]
        },
        {
          path: 'notifications',
          redirect: '/notifications/alerts',
          name: 'Notifications',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'alerts',
              name: 'Alerts',
              component: Alerts
            },
            {
              path: 'badges',
              name: 'Badges',
              component: Badges
            },
            {
              path: 'modals',
              name: 'Modals',
              component: Modals
            },
            {
              path: 'toaster',
              name: 'Toaster',
              component: Toaster
            }
          ]
        },
        {
          path: 'plugins',
          redirect: '/plugins/draggable',
          name: 'Plugins',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'draggable',
              name: 'Draggable Cards',
              component: Draggable
            },
            {
              path: 'calendar',
              name: 'Calendar',
              component: Calendar
            },
            {
              path: 'spinners',
              name: 'Spinners',
              component: Spinners
            }
          ]
        },

        {
          path: 'apps',
          name: 'Apps',
          redirect: '/apps/invoicing/invoice',
          component: {
            render(c) { return c('router-view') }
          },
          children: [
            {
              path: 'invoicing',
              redirect: '/apps/invoicing/invoice',
              name: 'Invoicing',
              component: {
                render(c) { return c('router-view') }
              },
              children: [
                {
                  path: 'invoice',
                  name: 'Invoice',
                  component: Invoice
                }
              ]
            }
          ]
        }
      ]
    },
    {
      path: '/workspace/:id',
      name: 'Workspace',
      component: Workspace,
    },
    {
      path: '/apps/email',
      redirect: '/apps/email/inbox',
      name: 'Email',
      component: EmailApp,
      children: [{
        path: 'compose',
        name: 'Compose',
        component: Compose
      },
      {
        path: 'inbox',
        name: 'Inbox',
        component: Inbox
      },
      {
        path: 'message',
        name: 'Message',
        component: Message
      }
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
        {
          path: 'register',
          name: 'Register',
          component: Register
        },
        {
          path: 'verify-account',
          name: 'VerifyAccount',
          component: VerifyAccount
        },
        {
          path: 'workArea/:id',
          name: 'WorkArea',
          component: WorkArea
        },
        {
          path: 'workArea',
          name: 'WorkArea',
          component: WorkArea
        },
      ]
    },
    // {
    //   path: '/drawings',
    //   redirect: '/drawings/404',
    //   name: 'Drawings',
    //   component: {
    //     render(c) { return c('router-view') }
    //   },
    //   children: [
    //     {
    //       path: '404',
    //       name: 'Page404',
    //       component: Page404
    //     },
    //     {
    //       path: 'workSpace/:id',
    //       name: 'WorkSpace',
    //       component: WorkSpace
    //     },
    //     {
    //       path: 'WorkSpace',
    //       name: 'WorkSpace',
    //       component: WorkSpace
    //     },


    //   ]
    // }
  ]
}
