
class BusinessHelper {
    constructor() {

    }

    getRequiredExitSeperation(floorPlan, diagonalDistance){
        
        if (floorPlan.buildingCode.code == "IBC") {

            if (floorPlan.isHighRise) {
                diagonalDistance = diagonalDistance / 4;
            } else {
                if (floorPlan.isSprinkleredBuilding) {
                    diagonalDistance = diagonalDistance / 3;
                } else {
                    diagonalDistance = diagonalDistance / 2;
                }
            }
        }
        
        if (floorPlan.buildingCode.code == "NFPA 101") {
            if (floorPlan.isSprinkleredBuilding){
                diagonalDistance = diagonalDistance / 3;
            } else {
                diagonalDistance = diagonalDistance / 2;
            }
        }

        if (floorPlan.buildingCode.code == "UBBL") {
            diagonalDistance = 5;
        }

        if (floorPlan.buildingCode.code == "BCA") {
            if(floorPlan.olfMainCategories.code == "Class 2 - Private Dwelling"){
                diagonalDistance = diagonalDistance / 3;
            }

            if(floorPlan.olfMainCategories.code == "Residential"){
                diagonalDistance = diagonalDistance / 9;
            }

            diagonalDistance = 9;
        }

        
          return Math.round(diagonalDistance * 100) / 100;
        
    }



}

export default BusinessHelper;