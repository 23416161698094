import axios from 'axios'
let apiUrl = process.env.VUE_APP_API_URL;

class AuthService {
    constructor() {
        this.token = window.localStorage.getItem('token');
        let userData = window.localStorage.getItem('user');
        let refreshToken = window.localStorage.getItem('refreshToken');
        if (userData !== 'undefined') {
            this.user = userData ? JSON.parse(userData) : null;
            if (this.token !== null) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(this.token).token;
                axios.defaults.headers.common['api-token'] = this.token;

                const tokenExpires = JSON.parse(refreshToken).expires; // Get token expiration time
                const currentTime = new Date().toISOString(); // Get current time in ISO format

                if (currentTime > tokenExpires) { // Check if token is expired
                    this.logout(); // Call logout method if the token has expired
                }
            }
        }
    }

    getUser() {
        api.call('get', apiUrl + 'Account/get-user')
            .then(({ data }) => {
                this.user = data;
            });
    }

    doLogin(data) {
        var url = apiUrl + 'auth/login';
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        this.token = null;
        this.user = null;
        return api.call('post', url, data)
            .then((response) => {
                return response.data
            });

    }

    register(data) {
        var url = apiUrl + 'auth/register';
        return api.call('post', url, data)
            .then((response) => {
                // console.log(response.data);
                return response.data
            });

    }

    recordLogin(token, user, refreshToken) {
        window.localStorage.setItem('token', JSON.stringify(token));
        window.localStorage.setItem('user', JSON.stringify(user));
        window.localStorage.setItem('refreshToken', JSON.stringify(refreshToken))
        this.token = token;
        this.user = user;
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token.token;
    }

    isSuperAdmin() {
        return this.user.isSuperAdmin;
    }

    getRole() {
        if (this.user.isSuperAdmin)
            return "admin";
        return this.user.role;
    }

    logout() {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + this.token;
        if (this.token) {
            api.call('post', apiUrl + 'auth/logout', { refreshToken : this.token })
                .then(({ data }) => {
                    // EventBus.$emit('userLoggedOut');
                    console.log('it works! data, dat', data)
                })
            // EventBus.$emit('userLoggedOut');
        }
        window.localStorage.removeItem('token');
        window.localStorage.removeItem('user');
        window.localStorage.removeItem('refreshToken');
        this.token = null;
        this.user = null;
        this.refreshToken = null;

    }

    check() {
        return !!this.token;
    }

    resetPassword(data) {
        let userData = JSON.parse(window.localStorage.getItem('user'));
        var url = apiUrl + `users/${userData.id}/update-password`;
        return api.call('patch', url, data)
            .then((response) => {
            return response.data
        });
    }

}

export default AuthService;
